@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Times New Roman,Times,serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.color1_background { background-color: #e4ecfc !important }
.color1_text { color: #e4ecfc !important }

.color2_background { background-color: #fbdebb !important }
.color2_text { color: #fbdebb !important }


.red_background { background-color: red }
.red_text { color: red }

.btn {
  @apply font-bold py-2 px-4 rounded;
}
.btn-blue {
  @apply bg-blue-500 text-white;
}
.btn-blue:hover {
  @apply bg-blue-700;
}
.btn_content {
}

.btn.small {
  @apply m-1 px-2 py-0;
}

.btn { box-shadow: 0 0 5px #ddd; margin: 10px; }
.btn-light { background-color: white;  }
.btn-primary { background-color: blue;  }
.btn svg { font-size: 12px !important;}

.tdIcons
{
  padding: 0 10px !important;
  vertical-align: middle;
}

svg[data-icon=info] { color: green; }
svg[data-icon=gear] { color: blue; }
svg[data-icon=message] { color: orange; }
svg[data-icon=headset] { color: rgb(20, 13, 95); }
svg[data-icon=users] { color: rgb(18, 120, 74); }
svg[data-icon=calendar-days] { color: brown; }
svg[data-icon=xmark] { color: red; }
svg[data-icon=check] { color: green; }
svg[data-icon=shield-halved] { color: rgb(127, 95, 0); }
svg[data-icon=clock] { color: rgb(207, 129, 12); }
svg[data-icon=phone] { color: rgb(78, 57, 141); }
svg[data-icon=mail] { color: rgb(185, 177, 61); }

.pageHeader { @apply mb-3 }
.pageTitle { @apply text-4xl font-extrabold dark:text-white }
.pageSubTitle { @apply text-2xl font-extrabold dark:text-white }


/* Notes BEGIN */

.timelinetable td 
{
  vertical-align: top;
  padding: 0px;
  padding-bottom: 0px;
}
.timelinetable .timeline .timeline1 .content
{
  text-align: right;
  border: 1px solid rgb(187, 187, 187);
  border-right: 5px solid blue;
  padding: 5px 5px 3px 20px;
  white-space: nowrap;
  margin-bottom: 2px;
}
.timelinetable .timeline .timeline3 .content
{
  border: 1px solid rgb(187, 187, 187);
  border-left: 5px solid blue;
  padding: 5px 5px 3px 20px;
  min-height: 57px;
  margin-bottom: 1px;
}

.timelinetable .timeline2
{
  border-collapse: collapse;
  position: relative;
}
.timelinetable tr:first-child .timeline2::before
{
  content: '';
  position: absolute;
  background-color: rgb(169, 169, 169);
  top: 50%;
  left: 45%;
  width: 5px;
  bottom: 0px;
  z-index: -10;
}
.timelinetable tr:last-child .timeline2::before
{
  content: '';
  position: absolute;
  background-color: rgb(169, 169, 169);
  top: 0px;
  left: 45%;
  width: 5px;
  height: 40px;
  z-index: -10;
}
.timelinetable .timeline2::before
{
  content: '';
  position: absolute;
  background-color: rgb(169, 169, 169);
  top: 0px;
  left: 45%;
  width: 5px;
  bottom: 0px;
  z-index: -10;
}
.timelinetable .timeline2
{
  background: linear-gradient(180deg, 
        rgba(0,0,0,0) 32px, 
        blue 33px, 
        blue 33px, 
        rgba(0,0,0,0) 34px
    );
    background-size:80%;
}
.timelinetable .timeline2 svg
{
  box-shadow: 0 0 5px blue;
  padding: 10px;
  border-radius: 50%;
  background-color: white;
  color: rgb(9, 9, 100);
  margin: 13px;
}

.timelinetable .aboutstate .timeline2 svg
{
  box-shadow: 0 0 5px green;
  color: green;
}

/* Notes END */


.react-confirm-alert-overlay {
  z-index: 200 !important;
}
.fade.modal-backdrop, .fade.modal, .modal-dialog, .modal-content
{
  z-index: 100 !important;
}


/* FULLCALENDAR */
.fc td, .fc th{
  background-color: white;
}
.fc-daygrid-event {
  white-space: normal !important;
  align-items: normal !important;
}
.eventCld
{
  border:1px solid #918d8d;
  width: 100%;
  padding: 2px 5%;
  border-radius: 3px;
  cursor: pointer;
}
.fc-h-event{ 
  background-color: red !important;
  border: 0px !important;
  position: relative;
}
.fc-event-main
{
  font-size: 120%;
  padding: 0px 10px;
}
.fc-toolbar-title{ 
    display: inline-block; 
    display: inline-flex;
    position: relative;
    vertical-align: middle;
    border:2px solid #2c3e50;
    border-radius: 5px;
    padding:7px 10px;
    font-size: 20px !important;
  }

  .fc{
  margin-bottom: 30px;
}
.fc-license-message{
  display: none !important;
}
/* FULLCALENDAR END */

/* DATATABLE BEGIN */
.rdt_TableBody{
  .rdt_TableRow:nth-child(even){background-color: #fbfafa;}
}

.rdt_TableHeadRow
{
  font-size: 130%;
  background-color: #5DA2D5 !important;
}
.rdt_TableRow:hover{
  background-color: rgba(205, 175, 114, 0.2) !important;
}

.input_height .btn_content {
  margin: 5.5px auto;
}

.content_bloc { @apply bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 } 
