.App {
  text-align: center;
}

body
{
  padding: 40px;
}

*{
  font-family: "Times New Roman", Times, serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.defaultmenu 
{
  padding: 0;
  margin: 0;
  height: 50px;
  margin-bottom: 10px;
}

.defaultmenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  
}

.defaultmenu li {
  float: left;
  position: relative;
  font-weight: bold;
  width: 130px;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  padding: 2px 3px;
  background-color: transparent;
}

.defaultmenu li a:hover, .defaultmenu li a.active
{
  background-color: #e4ecfc;
}

.defaultmenu li a {
  display: block;
  color: black;
  text-decoration: none;
  padding: 10px 0px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 2px grey;
}

.defaultmenu li ul {
  display: none;
  position: absolute;
  width:100px;
  
}

.defaultmenu li>ul {
  top: auto;
  left: auto;
  z-index: 99999;
}

.defaultmenu ul li:hover ul, .defaultmenu ul li.over ul {
  display: block;
}

label{ font-weight: bold;}
.value_zone{ min-height: 30px; width: 100%; border:2px solid #ddd; padding:3px 6px; border-radius: 5px; margin-bottom: 20px;}

.header1{ font-size: 20px; font-weight: bold; margin: 20px 10px; margin-bottom: 40px; text-decoration: underline;}

.contentPage{ padding: 10px; text-align: left; }

.mbbtn 
{ display: inline-block; margin: 3px 7px; border:1px solid rgb(138, 138, 138); cursor: pointer; border-radius: 5px; padding: 3px 10px; box-shadow: 0 0 10px #eee; }

/* table BEGIN */
.table {
  border-collapse: collapse;
  width: 100%;
}

.table td, .table th {
  border: 1px solid #371e02;
  padding: 8px;
}

.table tr:nth-child(even) td{background-color: #78bae4;}

.table tr:hover td {background-color: #b1b1b1;}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #3f7fbf;
  color: white;
}

/* table END */

.cursor_pointer { cursor: pointer;}




/* MODALs */

.modal-90w { width: 100% !important; border: 1px solid red; }

/* MODALs */

.logoImage{
  float: left;
}


.align-right { text-align: right; }
.font-size-30 { font-size: 30px; }
.font-weight-bold { font-weight: bold; }


/* FULLCALENDAR */
.fc-toolbar { text-transform: capitalize; }
.fc-datagrid-cell-main { white-space: normal; }


/* Pick navigation */
.pick_navigation
{
  display: flex;
  flex-direction: row-reverse;
  background-color: transparent !important;
  border: none;
}

.pick_navigation .nav {
  padding: 10px;
  text-align: center;
}

.pick_navigation img {
  box-shadow: 0 0 10px #282c34;
  border-radius: 5px;
}

/* Pick Photos */
.joined-files-list{
  display: flex;
  flex-wrap: wrap;
}
.imagePicker {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #b3afaf;
  background-size: cover;
}


/* Style the button and place it in the middle of the container/image */
.imagePicker .btn {
  position: absolute;
  top: 0%;
  right: 0%;
  /*-ms-transform: translate(-10%, -90%);*/
  background-color: #faf7f7;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0 0 0 5px;
}
.imagePicker .btn svg { color: red }

.imagePicker .btn:hover {
  background-color: black;
}
